// If you don't want to use TypeScript you can delete this file!
import React from "react";
import styled from "styled-components";
import { PrimaryButton, SEO } from "@/components";
import { MainLayout } from "@/layouts";
import MainPeepSvg from "@/assets/main-peep.svg";
import LogoSvg from "@/assets/logo.svg";
import { navigate } from "gatsby";
import { useGameContext } from "@/contexts";
import { AuthApi, RedirectIfLoggedIn, useAuthContext } from "@/components/Auth";

const MainPeepImage = styled.img`
	max-width: 500px;
	width: 100%;
`;

const MainFormContainer = styled.form`
	max-width: 400px;
	width: 100%;
`;

type AuthViewName = "SignIn" | "ChangePass";

function Index() {
	const { authenticate, authInfo } = useGameContext();
	const [currentView, setCurrentView] = React.useState<AuthViewName>("SignIn");
	const [cognitoChallengeResponse, setCognitoChallengeResponse] = React.useState<any>();
	const onChangeView = (newView: AuthViewName) => {
		setCurrentView(newView);
	};

	React.useEffect(() => {
		if (!cognitoChallengeResponse) {
			return;
		}
		if (cognitoChallengeResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
			setCurrentView("ChangePass");
		}
	}, [cognitoChallengeResponse]);

	return (
		<RedirectIfLoggedIn>
			<MainLayout navbar={false}>
				<SEO title="Home" />
				<div className="flex flex-col items-center mt-4">
					<div className="py-8 lg:py-32"></div>
					{/* <MainPeepImage src={MainPeepSvg} /> */}
					{/* <h1 className="text-5xl font-bold">Realpublik Management</h1> */}
					<div className="bg-white bg-opacity-75 p-4 flex items-center justify-center">
						<img src={LogoSvg} alt="Realpublik logo" width="500px" />
					</div>
					{/* <h2 className="text-center text-lg mt-4 text-gray-600">
						Realpublik Management es el juego de Gestión Pública en el que pondrás en práctica las habilidades directivas más exigidas en el sector público. Es una inmersión técnica y emocional que te permitirá desarrollar
						experiencia en un ambiente realista pero controlado
					</h2> */}
					{currentView === "SignIn" && (
						<SignInForm
							changeView={onChangeView}
							updateChallengeResponse={(newResponse: any) => {
								setCognitoChallengeResponse(newResponse);
							}}
						/>
					)}
					{currentView === "ChangePass" && cognitoChallengeResponse && <ChangePassForm changeView={onChangeView} cognitoChallengeResponse={cognitoChallengeResponse} />}
				</div>
			</MainLayout>
		</RedirectIfLoggedIn>
	);
}

interface IAuthFormProps {
	changeView: (newView: AuthViewName) => void;
}

interface IChangePassFormProps extends IAuthFormProps {
	cognitoChallengeResponse: any;
}

function ChangePassForm(props: IChangePassFormProps) {
	const authContext = useAuthContext();
	const [error, setError] = React.useState<string>();
	const [isLoading, setIsLoading] = React.useState<boolean>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsLoading(true);
		try {
			const newPass = event.currentTarget["new-pass"].value;
			const newPassConfirm = event.currentTarget["new-pass-confirm"].value;
			// Change Pass
			try {
				const cognitoRes = await AuthApi.completeNewPassword(props.cognitoChallengeResponse, newPass);
				// await authContext.refreshUser(true);
			} catch (error) {
				setError(error.message);
			}
		} catch (error) {
			setError("Los datos son incorrectos. Vuelve a intentar");
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<>
			<MainFormContainer className="flex flex-col mt-4" onSubmit={onSubmit}>
				<label className="block">
					<span className="text-gray-700">Nueva contraseña</span>
					<input name="new-pass" type="password" className="mt-1 block w-full" />
				</label>
				<label className="block mt-4">
					<span className="text-gray-700">Repite la Nueva Contraseña</span>
					<input name="new-pass-confirm" type="password" className="mt-1 block w-full" />
				</label>
				<PrimaryButton className="mt-4 flex justify-center items-center" type="submit">
					{!isLoading && "Cambiar Contraseña"}
					{isLoading && (
						<>
							<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
							</svg>
							Cargando
						</>
					)}
				</PrimaryButton>
			</MainFormContainer>
			{error && <p className="text-red-600">{error}</p>}
		</>
	);
}

interface ISignInFormProps extends IAuthFormProps {
	updateChallengeResponse: (newResponse: any) => void;
}

function SignInForm(props: ISignInFormProps) {
	const [error, setError] = React.useState<string>();
	const [isLoading, setIsLoading] = React.useState<boolean>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsLoading(true);
		try {
			const email = event.currentTarget["email"].value;
			const password = event.currentTarget["password"].value;
			// await authenticate(email, passcode);
			// await navigate("/game");
			// SignIn
			try {
				const cognitoRes = await AuthApi.signIn({ username: email, password });
				props.updateChallengeResponse(cognitoRes);
			} catch (error) {
				console.info("error auth", error);
				setError(error.message);
			}
		} catch (error) {
			setError("Los datos son incorrectos. Vuelve a intentar");
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<>
			<MainFormContainer className="flex flex-col mt-4" onSubmit={onSubmit}>
				<label className="block">
					<span className="text-black font-bold px-2 bg-white">Email</span>
					<input name="email" type="text" className="mt-1 block w-full opacity-80" placeholder="" />
				</label>
				<label className="block mt-4">
				<span className="text-black font-bold px-2 bg-white">Contraseña</span>
					<input name="password" type="password" className="mt-1 block w-full opacity-80" />
				</label>
				<PrimaryButton className="mt-4 flex justify-center items-center" type="submit">
					{!isLoading && "Ingresar"}
					{isLoading && (
						<>
							<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
							</svg>
							Cargando
						</>
					)}
				</PrimaryButton>
			</MainFormContainer>
			{error && <p className="text-red-600">{error}</p>}
		</>
	);
}

export default Index;
